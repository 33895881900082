import { Component, Input, OnInit, Output,EventEmitter, ViewChild } from '@angular/core';
import { moveItemInArray, CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { PreBoardingService } from "src/app/shared/pre-boarding.service";
import { saveAs } from "file-saver";
import { MatTableDataSource } from "@angular/material/table";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { DATE_FORMATE, SERVER_API_URL } from 'src/app/app-constants';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';



@Component({
  selector: 'app-candidatereports',
  templateUrl: './candidatereports.component.html',
  styleUrls: ['./candidatereports.component.scss']
})
export class CandidatereportsComponent implements OnInit {
  reportsDataSource = new MatTableDataSource([]);
  originalData: any;
  pageSize=10;
  empSelected=['all'];
  documentSelected=['all'];
  loginSelected="all";
  disableSelect=true;
  disableDocumentStatus=true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  showFirstLastButtons=true;
  searchText: any;
  eventtyperequired = false;
  displayedColumns: string[]=[
    "srNo",
    "location",
    "employeeId",
    "employeeName",
    "employeeFatherName",
    'employeegender',
    "employeeMaritalStatus",
    "qualification",
    "degree",
    "employeePassportNo",
    "employeeMobile1",
    'employeePersonalEmailId',
  ];
  datePipe = new DatePipe('en-US');
  public searchFormReports: FormGroup=this.fb.group({
    Gender: new FormControl(''),
    bloodGroup: new FormControl(''),
    qualification: new FormControl(''),
    maritalStatus: new FormControl(''),
    eventType:  new FormControl('', [Validators.required]),
    FromDate:new FormControl('', [Validators.required]),
    ToDate: new FormControl('', [Validators.required]),
    TotalPreviousExperience:new FormControl(''),
  });
  reportsLength: any;
  constructor(private preBoardingService: PreBoardingService, private fb: FormBuilder, private router: Router) {}

  ngOnInit(): void {
    this.empSelected=['all'];
    this.documentSelected=['all'];
    this.loginSelected="all";
    this.disableSelect=true;
    this.disableDocumentStatus=true;
    this.reportsDataSource=new MatTableDataSource([]);
    this.advancedSearch('search');
    // $('#mat-select-0-panel').css("min-width",100)
    this.searchFormReports.valueChanges.subscribe(x => {
      if ((x.FromDate || x.ToDate)&& (!x.Gender && !x.bloodGroup && !x.qualification && !x.maritalStatus && !x.eventType && !x.TotalPreviousExperience)) {
        // this.searchFormReports.get('eventType').setValidators([Validators.required]);
        this.eventtyperequired=true;
      } else {
        this.eventtyperequired = false;
      }
    });
  }

  disabledSelect () {
    if (this.empSelected.includes('all')) {
      this.disableSelect=true;
      this.empSelected=['all'];
    } else if (!(this.empSelected.includes('all'))) {
      this.disableSelect=false;
    }
  }
  ngAfterViewInit() {
    this.reportsDataSource.paginator = this.paginator;
}

  disableDocumentationStatus () {
    if (this.documentSelected.includes('all')) {
      this.disableDocumentStatus=true;
      this.documentSelected=['all'];
    } else if (!(this.documentSelected.includes('all'))) {
      this.disableDocumentStatus=false;
    }
  }
  advancedSearch (option) {
    let Gender=this.searchFormReports.get('Gender').value;
    let bloodGroup=this.searchFormReports.get('bloodGroup').value;
    let qualification=this.searchFormReports.get('qualification').value;
    let maritalStatus=this.searchFormReports.get('maritalStatus').value;
    let eventType=this.searchFormReports.get('eventType').value;
    let FromDate=this.datePipe.transform(this.searchFormReports.get('FromDate').value,'yyyy-MM-dd');
    let ToDate=this.datePipe.transform(this.searchFormReports.get('ToDate').value,'yyyy-MM-dd');
    let TotalPreviousExperience=this.searchFormReports.get('TotalPreviousExperience').value;

    let url;
    let payload: any = {};

    if (option==='search') {
      url='/api/employeePersonalInfoReport/filter';
    } else if (option === 'export') {
      url='/api/employeePersonalInfoReport/filter/export'
    }

    if (Gender === '') {
      Gender='all';
      payload.gender= Gender;
    } else {
      Gender = this.searchFormReports.get('Gender').value;
      payload.gender=Gender;
    }

    if (bloodGroup==='') {
      bloodGroup='all';
      payload.bloodGroup= bloodGroup
    } else {
      payload.bloodGroup= bloodGroup
    }
    if (qualification==='') {
      qualification='all';
      payload.qualification = qualification;
    } else {
      qualification=this.searchFormReports.get('qualification').value;
      payload.qualification = qualification;
    }
    if (maritalStatus) {
      payload.maritalStatus= maritalStatus;
    } else {
      maritalStatus='all';
      payload.maritalStatus= maritalStatus;
    }

    if (eventType) {
      payload.eventType = eventType;
    } else {
      eventType='all';
      payload.eventType = eventType;
    }

    if (FromDate) {
      payload.fromDate =FromDate;
    } else {
      FromDate='';
      payload.fromDate =FromDate;
    }
    if (ToDate) {
      payload.toDate=ToDate;
    } else {
      ToDate='';
      payload.toDate=ToDate;
    }

    if (TotalPreviousExperience) {
      payload.totalPreviousExperience = TotalPreviousExperience;
    } else {
      TotalPreviousExperience='all';
      payload.totalPreviousExperience = TotalPreviousExperience;
    }

    if (option==='search') {
      this.preBoardingService.getPBFilter(url, payload).subscribe(res => {
        this.reportsDataSource=new MatTableDataSource(res);
        this.reportsDataSource.paginator = this.paginator;
        this.reportsLength=this.reportsDataSource.data.length;
      });
    } else if (option==='export') {
      this.preBoardingService.getexport(url, payload).subscribe((res) => {
        saveAs(res, "Candidates Report.xlsx");
      });
    }
  }

  applyFilter (event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.reportsDataSource.filter = filterValue.trim().toLowerCase();
  }
  
  reset(){
    this.searchFormReports.patchValue({
      Gender: '',
      bloodGroup:'',
      qualification:'',
      maritalStatus:'',
      eventType:'',
      FromDate:'',
      ToDate:'',
      TotalPreviousExperience:'',
    });
    this.searchText=null;
    this.ngOnInit();
  }
  redirectToDashboard() {
    this.router.navigate(['dashboard']);
  }
}

